<div *ngIf="align > 0; else noAlign" style="width:100%">
  
    <div [fxFlex]="align"></div>
    <div  [style.font-size]="fontSize"
    
    [ngStyle.lt-md]="{ 'font-size': MobfontSize }"  [ngStyle]="{'font-family': data?.websiteFontFamily}"
    
    [innerHTML]="trustedHtmlContent" [id]="text"></div>
    <div [fxFlex]="align"></div>
  </div>
  
  
  <ng-template #noAlign style="width:100%">

    <div [id]="text" style="width:100%" [style.font-size]="fontSize" [ngStyle.lt-md]="{ 'font-size': MobfontSize }" [ngStyle]="{'font-family': data?.websiteFontFamily}"   [innerHTML]="trustedHtmlContent"></div>
  </ng-template>
  