<div  *ngIf="display">
    <div >
        <mat-toolbar  class="header-v2 primary" fxLayoutAlign="space-between center"
            [style.height]="data.mobileHeaderHeight">
            <mat-toolbar-row style="height: 100%;border-bottom: 1px solid white;" class="theme-container"
                fxLayoutAlign="space-around center">

                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <section>
                            <calsoft-logo [sitename]="data.siteName" [sitename]="data.siteName" [type]="'light'">
                            </calsoft-logo>

                        </section>
                    </div>


                </div>

                <div fxLayoutAlign="center center">
                    <div>
                        <h3 style="font-size: 27px;margin-bottom: 0px;">{{data.headerNotificationContent}}</h3>
                    </div>
                </div>

                <div *ngIf="data.disableLoginHeader==0">
                    <div class="login padding-10" style="font-size:20px" *ngIf="!userLoggedIn"
                        (click)="SignIn('login')">Login</div>
                </div>
            </mat-toolbar-row>

        </mat-toolbar>

        <div fxLayoutAlign="center center" class="container">

            <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
        </div>
    

       
    </div>

    <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
        fxLayoutAlign="space-between center" style="z-index: 1000;">
        <mat-toolbar-row class=" theme-container" style="height: 100%;" fxLayoutAlign="space-around center">
            <section class="padding-10" style="padding-left: 7%;">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>
            <section fxLayoutAlign="center center">
                <div>
                    <h3 style="font-size: 27px;margin-bottom: 0px;">{{data.headerNotificationContent}}</h3>
                </div>
            </section>
            <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

                <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                    <div style="color: #ffffff" fxLayout="column">
                        <small>Hello, Sign in</small>
                        <span>Account & Lists</span>
                    </div>
                </button>

                <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>

              
            </section>
            
        </mat-toolbar-row>

    </mat-toolbar>

</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>


    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>

</div>